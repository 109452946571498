var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-hash-jump-point atmo-content-group",
      attrs: { id: "atmo-purchase-terms" },
    },
    [
      _c("h3", [_vm._v("Условия закупки")]),
      _c(
        "ul",
        { staticClass: "atmo-content-block-2-columns atmo-no-item-separator" },
        [
          _c("li", [
            _c("dt", [_vm._v("Предмет закупки:")]),
            _c("dd", {
              domProps: {
                textContent: _vm._s(this.$props.purchaseitem.description),
              },
            }),
          ]),
          this.$props.purchaseitem.purchase_object
            ? _c("li", [
                _c("dt", [_vm._v("Объект закупки:")]),
                _c("dd", {
                  domProps: {
                    textContent: _vm._s(
                      this.$props.purchaseitem.purchase_object
                    ),
                  },
                }),
              ])
            : _vm._e(),
          _c("li", [
            _c("dt", [_vm._v("Тип закупки:")]),
            _c("dd", {
              domProps: {
                textContent: _vm._s(this.$props.purchaseitem.type_title),
              },
            }),
          ]),
          _c("li", [
            _c("dt", [_vm._v("Продолжительность закупочной сессии:")]),
            _c("dd", {
              domProps: {
                textContent: _vm._s(
                  this.$props.purchaseitem.duration_type_title
                ),
              },
            }),
          ]),
          !_vm.pivc.isPurchaseOutATMO &&
          this.$props.purchaseitem.with_advance &&
          this.$props.purchaseitem.advance_percentage
            ? _c("li", [
                _c("dt", [_vm._v("Размер аванса, %:")]),
                _c("dd", {
                  domProps: {
                    textContent: _vm._s(
                      this.$props.purchaseitem.advance_percentage
                    ),
                  },
                }),
              ])
            : _vm._e(),
          _c("li", [
            _vm.pivc.isPurchaseOutATMO
              ? _c("dt", [_vm._v("Общая стоимость, руб.:")])
              : _c("dt", [_vm._v("Начальная цена контракта, руб.:")]),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm._f("formatnumber")(
                    this.$props.purchaseitem.starting_price
                  )
                )
              ),
            ]),
          ]),
          _c("li", [
            _vm.pivc.isPurchaseOutATMO
              ? _c("dt", [_vm._v("Дата заключения договора:")])
              : _c("dt", [_vm._v("Дата и время размещения закупки:")]),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm._f("formatdate")(
                    this.$props.purchaseitem.publication_date,
                    "withSeconds"
                  )
                )
              ),
            ]),
          ]),
          !_vm.pivc.isPurchaseOutATMO
            ? _c("li", [
                _c("dt", [
                  _vm._v("Дата и время окончания подачи предложений:"),
                ]),
                _c("dd", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatdate")(
                        this.$props.purchaseitem.proposal_accept_end_date,
                        "withSeconds"
                      )
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          !_vm.pivc.isPurchaseOutATMO
            ? _c("li", [
                _c("dt", [_vm._v("Планируемая дата заключения договора:")]),
                _c("dd", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatdate")(
                        this.$props.purchaseitem
                          .planned_contract_signature_date,
                        "onlyDate"
                      )
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.pivc.isPurchaseOutATMO
            ? _c("li", [
                _c("dt", [_vm._v("Дата исполнения договора:")]),
                _c("dd", [
                  this.$props.purchaseitem.planned_fulfillment_date
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatdate")(
                              this.$props.purchaseitem.planned_fulfillment_date,
                              "onlyDate"
                            )
                          )
                        ),
                      ])
                    : _c("span", [_vm._v("—")]),
                ]),
              ])
            : _vm._e(),
          !_vm.pivc.isPurchaseOutATMO
            ? _c("li", [
                _c("dt", [_vm._v("Приложение заказчика к объявлению:")]),
                _c(
                  "dd",
                  _vm._l(
                    this.$props.purchaseitem.attachments,
                    function (attachment, index) {
                      return _c(
                        "a",
                        {
                          key: index,
                          staticClass: "atmo-button-link",
                          staticStyle: {
                            color: "var(--green-color) !important",
                          },
                          attrs: {
                            href: _vm.$getAttachmentDownloadLink(
                              attachment.file_id
                            ),
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(attachment.name))]
                      )
                    }
                  ),
                  0
                ),
              ])
            : _vm._e(),
          !_vm.pivc.isPurchaseOutATMO
            ? _c("li", [
                _c("dt", [
                  _vm._v(
                    "Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством РФ:"
                  ),
                ]),
                _c("dd", [
                  this.$props.purchaseitem.unilateral_contract_termination_info
                    ? _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            this.$props.purchaseitem
                              .unilateral_contract_termination_info
                          ),
                        },
                      })
                    : _c("span", [_vm._v("Нет")]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }